//
// Forms
//


// Form group
.form-group {
	margin-bottom: 2rem;

	&.form-group-marginless,
	&.form-group-last {
		margin-bottom: 0px;
	}

	label {
		font-size: 1rem;
		font-weight: 400;
	}

	.invalid-feedback,
	.valid-feedback {
		font-weight: 400;
	}

	.form-text {
		font-size: 0.9rem;
	}

	// Space sizing
	&.form-group-md {
		margin-bottom: 1rem;
	}

	&.form-group-sm {
		margin-bottom: 0.5rem;
	}

	&.form-group-xs {
		margin-bottom: 0.25rem;
	}

	&.form-group-last {
		margin-bottom: 0rem;
	}
}

// Form control
.form-control {
	// &:focus,
	// &:active {
	// 	box-shadow:none !important;
	// }

	&[readonly] {
		background-color: $input-readonly-bg;
	}

	// Pill style
	&.form-control-pill {
		border-radius: 50px;
	}
}

// Custom inputs
/*.custom-select {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}*/

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 1px);
    padding: 0.55rem 2rem 0.55rem 1rem;
    font-size: 0.85rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5"%3e%3cpath fill="%23343a40" d="M2 0L0 2h4zm0 5L0 3h4z"/%3e%3c/svg%3e') right 1rem center/8px 10px no-repeat;
    border: 1px solid #e2e5ec;
    border-radius: 0.25rem;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-file {
	width: 100%;
}

.custom-file-input:focus ~ .custom-file-label {
	box-shadow: none !important;
}

.custom-file-label {
	text-align: left;

	&:after {
	    float: left;
	}
}


// Input Group
.input-group {
	@include kt-icons-size((lineawesome: 1.4rem, fontawesome: 1.2rem, flaticon: 1.2rem));

	i {
		color: $input-group-icon-color;
		line-height: 0;
	}

	.form-control.is-valid + .input-group-append,
	.form-control.is-invalid + .input-group-append {
		margin-left: 0;
	}

	.input-group-prepend + .form-control.is-valid,
	.input-group-prepend + .form-control.is-invalid {
		margin-left: 1px;
	}
}

// Validation
.validated {
	.valid-feedback,
	.invalid-feedback {
		display: block;
	}
}
