// Import the ag-Grid material theme

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";

//@import 'ag-grid-community/dist/styles/ag-theme-material.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/sass/custom/shared-components.scss';

/* theme custom angular material */

//@import '@angular/material/theming';

// Be sure that you only ever include 'mat-core' mixin once!
// it should not be included for each theme.
//@include mat-core();

@mixin tooltip-background($color-name) {
	$color-var: unquote("var(--bs-#{$color-name})");

	.bg-#{$color-name}:has(>.mdc-tooltip__surface) {
		background-color: inherit !important;
		--bs-bg-opacity: inherit !important;

		> .mdc-tooltip__surface {
			--mdc-plain-tooltip-container-color: #{$color-var};
		}
	}
}


@include tooltip-background(primary);
@include tooltip-background(danger);
@include tooltip-background(success);
@include tooltip-background(info);
@include tooltip-background(warning);

//migracion
/*.mat-mdc-dialog-container .mdc-dialog__surface {
   padding: 24px;
}*/

ngb-datepicker-navigation-select > .form-select {
	background-position: right 0.4rem center;
}

.fieldset-border > legend {
	float: none !important;
}

@include tooltip-background(warning);

// define a real custom palette (using http://mcg.mbitson.com)
$bv-brand: (
	50: #ffffff,
	100: #dde6f3,
	200: #b4c9e4,
	300: #7fa3d1,
	400: #6992c9,
	500: #5282c1,
	600: #4072b4,
	700: #38649d,
	800: #305687,
	900: #284770,
	A100: #ffffff,
	A200: #dde6f3,
	A400: #6992c9,
	A700: #38649d,
	contrast: (50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: rgba(black, 0.87),
		400: rgba(black, 0.87),
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		A100: rgba(black, 0.87),
		A200: rgba(black, 0.87),
		A400: rgba(black, 0.87),
		A700: white,
	),
);

$bv-orange: (
	50: #ffffff,
	100: #fff7f4,
	200: #fecdbd,
	300: #fc9977,
	400: #fc8259,
	500: #fb6c3b,
	600: #fa551d,
	700: #f44205,
	800: #d63a04,
	900: #b83204,
	A100: #ffffff,
	A200: #fff7f4,
	A400: #fc8259,
	A700: #f44205,
	contrast: (50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: rgba(black, 0.87),
		400: rgba(black, 0.87),
		500: white,
		600: white,
		700: white,
		800: white,
		900: white,
		A100: rgba(black, 0.87),
		A200: rgba(black, 0.87),
		A400: rgba(black, 0.87),
		A700: white,
	),
);

// mandatory stuff for theming
//: mat-palette($bv-brand);
//$bv-palette-accent: mat-palette($bv-orange);

// include the custom theme components into a theme object
//$bv-theme: mat-light-theme($bv-palette-primary, $bv-palette-accent);

// include the custom theme object into the angular material theme
//@include angular-material-theme($bv-theme);

/* Scrollbar styles */

:root {
	--background-primary: #646c9a;
	--color-primary: #646c9a;
}

*::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

*::-webkit-scrollbar-track {
	border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
	background-color: rgb(127, 127, 127);
	border-radius: 100px;
}

// Quitar
body {
	width: 100%;
	min-height: 100vh;
	background-color: #f2f3f8;
	overflow: hidden;
}

fieldset.fieldset-focus:focus-within {
	background-color: rgb(#646c9a, 13%);
}

/* Metronic */

// header base skins
.kt-header-base-light {
	@import './assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
	@import './assets/sass/global/layout/header/skins/base/dark.scss';
}

// header menu skins
.kt-header-menu-light {
	@import './assets/sass/global/layout/header/skins/menu/light.scss';
}

.kt-header-menu-dark {
	@import './assets/sass/global/layout/header/skins/menu/dark.scss';
}

// brand skins
.kt-brand-dark {
	@import './assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
	@import './assets/sass/global/layout/brand/skins/light.scss';
}

// aside skins
.kt-aside-dark {
	@import './assets/sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
	@import './assets/sass/global/layout/aside/skins/light.scss';
}

.ng-select.ng-select-single .ng-select-container {
	height: calc(1.5em + 1rem + 2px) !important;
	transition: all 0.3s;

	&:focus,
	&:active,
	&:hover {
		border-color: #007eff;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
	}
}

.ng-select .ng-select-container {
	min-height: calc(1.5em + 1rem + 2px) !important;
	font-size: 11px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	font-size: 11px;
}

.nav-tabs {
	margin-bottom: 5px !important;
}

ng-select.ng-invalid.ng-touched {
	.ng-select-container {
		border-color: #dc3545;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
		// padding-right: calc(1.5em + 1.3rem);
		padding-right: calc(0.55em + 1.3rem);
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd397a' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd397a' stroke='none'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right calc(0.375em + 0.325rem) center;
		background-size: calc(0.75em + 0.65rem) calc(0.75em + 0.65rem);
	}
}

.form-control {
	&[readonly] {
		background-color: #f9f9f9;
	}

	&:focus,
	&:active {
		border-color: #007eff;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
	}
}

.mat-mdc-dialog-container {
	padding: 0 !important;
}

.mat-mdc-dialog-content {
	padding: 0 !important;
	margin: 0 !important;
}

// .kt-portlet {
//     margin-bottom: 0 !important;
// }

.mat-mdc-dialog-title {
	height: auto !important;
}

/* kt-portlet */
// .kt-portlet .kt-portlet__body {
//     padding: 1% 0;

//     .kt-grid__item {
//         margin: 0% 0% !important;
//         padding: 0% 1% 0% 1% !important;
//         flex: 0 0 24% !important;
//     }
// }

.kt-header-label-dashboard {
	width: 100% !important;
	justify-content: space-between;
}

.btn:focus:not(.btn-elevate),
.swal2-popup .swal2-styled:focus {
	border-color: #007eff !important;
	box-shadow: inset 0 28px 20px rgba(0, 0, 0, 0.1), 0 0 0 5px rgba(0, 126, 255, 0.2) !important;
}

// Ng Multiselect dropdown
.dropdown-btn {
	font-size: 12px;
}

.multiselect-item-checkbox input + div {
	color: #646c9a !important;
	font-size: 11px !important;
	padding-left: 3em !important;
}

.nav-tabs .nav-item .nav-link {
	color: rgba(0, 0, 0, 0.87) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group,
.mat-tab-label,
.mat-mdc-tab-link {
	font-family: inherit;
}

.mat-mdc-tab-header {
	position: sticky !important;
	top: 0;
	background-color: #ffffff;
	z-index: 20;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-label {
	font-size: 13.5px;
	font-family: inherit;
}

.sumax-label {
	color: #646c9a !important;
}

.mat-mdc-selection-list {
	// height: 102px;
	overflow: auto;
	outline: none;
	border: solid 1px rgb(226, 229, 236);
	padding-top: 0 !important;
	// top: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
.mat-list-option {
	height: 35px !important;
	font-size: 13px !important;
	width: 100%;
	border-bottom: solid 1px rgb(226, 229, 236);
	left: 0;
	z-index: 1;
	// top: calc(3em - 16px);
}

.mat-mdc-checkbox-touch-target {
	height: calc(var(--mdc-checkbox-state-layer-size) + 5) !important;
	width: calc(var(--mdc-checkbox-state-layer-size) + 5) !important;
}

.mat-mdc-checkbox {
	--mdc-checkbox-state-layer-size: 20px;
}

.mdc-form-field {
	--mdc-form-field-label-text-font: "Soho Gothic Pro";
	--mdc-form-field-label-text-size: 11px;
	--mdc-form-field-label-text-line-height: 16px;
}

.mat-mdc-option {
	--mdc-form-field-label-text-font: "Soho Gothic Pro";
	--mat-option-label-text-size: 13px;
}

div.mat-mdc-select-panel {
	padding: 0 !important;
}

html {
	--mdc-icon-button-icon-size: 14px;
	--mdc-plain-tooltip-supporting-text-font: "Soho Gothic Pro";
	--mdc-plain-tooltip-supporting-text-size: 12px;
}

.btn-success {
	--bs-btn-color: #fff;
	--bs-btn-bg: #0abb87;
	--bs-btn-border-color: #0abb87;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #099f73;
	--bs-btn-hover-border-color: #08966c;
	--bs-btn-focus-shadow-rgb: 47, 197, 153;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #08966c;
	--bs-btn-active-border-color: #088c65;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #0abb87;
	--bs-btn-disabled-border-color: #0abb87;
	color: #fff;
}

.btn-danger {
	--bs-btn-color: #fff;
	--bs-btn-bg: #fd397a;
	--bs-btn-border-color: #fd397a;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #d73068;
	--bs-btn-hover-border-color: #ca2e62;
	--bs-btn-focus-shadow-rgb: 253, 87, 142;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #ca2e62;
	--bs-btn-active-border-color: #be2b5c;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #fd397a;
	--bs-btn-disabled-border-color: #fd397a;
	color: #fff;
}


.mat-mdc-checkbox {
	--mdc-form-field-label-text-font: "Soho Gothic Pro";
	--mdc-form-field-label-text-size: 11px;
	--mdc-form-field-label-text-weight: 400;
	--mdc-dialog-supporting-text-line-height: 13px;
	--mdc-checkbox-state-layer-size: 24px;
}

.mat-mdc-tab-list {
	--mat-tab-header-label-text-size: 14px;
	--mat-tab-header-label-text-font: "Soho Gothic Pro";
	--mat-tab-header-label-text-weight: 600;
}

.mat-mdc-menu-item {
	--mat-menu-item-label-text-size: 14px;
	--mat-menu-item-label-text-font: "Soho Gothic Pro";
	--mat-menu-item-label-text-weight: 400;
	min-height: 31px !important;
}
